.date-card {
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .date-card.selected {
    border-color: #007bff;
    background-color: #f8f9fa;
  }
  
  .date-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.3);
  }
  
  .weekday {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .date {
    font-size: 1.25rem;
  }