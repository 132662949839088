body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
}

.mobile-nav {
  padding-top: 100px;
}

@media (max-width: 1600px) {
  .container-custom {
    max-width: 1200px !important;
    margin: 0 auto;
  }
}
@media (max-width: 1400px) {
  .container-custom {
    max-width: 1000px !important;
    margin: 0 auto;
  }
}
@media (max-width: 1200px) {
  .container-custom {
    max-width: 800px !important;
    margin: 0 auto;
  }
}


.features-section {
  background: #f9f9f9;
}

@media (max-width: 768px) {
  /* .profile-picture {
    width: 80px !important;
    height: 80px !important;
    border: 8px solid #edeaff !important;
  } */

  .bg-gradient-profile {
    height: 170px !important;
  }
  .profile-box {
    height: 310px !important;
    border-radius: 22px;
    box-shadow: 0px 4px 60px 0px #00000012;
  }
}
.box-shadow-common {
  box-shadow: 0px 4px 60px 0px #00000012 !important;
}
.bg-white {
  background-color: white !important;
}
.social-icon {
  width: 40px;
  height: 40px;
}
.margin-left-250 {
  margin-left: 220px;
}
@media (min-width: 992px) {
  .profile-box-main-lg {
    border-radius: 22px;
    box-shadow: 0px 4px 60px 0px #00000012;
    background-color: white;
  }
}
@media (max-width: 992px) {
  .margin-left-250 {
    margin-left: 0px;
  }
  .mobile-nav {
    position: fixed !important;
    z-index: 100;
    background: #fff;
    min-width: 220px !important;
    top: 0 !important;
    left: -100%;
    height: 103vh !important;
  }
  .nav-logo {
    width: 42px !important;
    margin-left: 0px !important;
  }

  .sidebar-padding {
    padding-top: 50px !important;
  }
  .social-icon {
    width: 30px;
    height: 30px;
  }
}

.duration-300 {
  transition: all 300ms;
}

.logout-btn {
  background-color: #ffeaf3;
  color: #fe2a88;
  font-size: 14px;
  padding: 15px 54px;
  width: 100%;
  line-height: 0%;
  border-radius: 12px;
  border: 0;
}

.sidebar-padding {
  padding-top: 100px;
}

.nav-logo {
  width: 81px;
  margin-left: 30px;
}

.avatar {
  width: 32px;
  height: 32px;
}

.bg-light-blue {
  background-color: #f3f8fb;
}

.hide-opacity {
  opacity: 0;
  pointer-events: none;
}

.pointer-event-auto {
  pointer-events: visible !important;
}
