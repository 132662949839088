@import url("https://fonts.googleapis.com/css2?family=Anton&family=Manrope:wght@200..800&display=swap");

:root {
  /* Font Sizes */
  --font-size-xs: 14px;
  --font-size-s: 16px;
  --font-size-sm: 18px;
  --font-size-md: 20px;
  --font-size-lg: 24px;
  --font-size-xl: 48px;
  --font-size-xxl: 74px;

  /* Font Family */
  --ff-manrope: "Manrope", sans-serif;
  --ff-anton: "Anton", sans-serif;

  /* Colors */
  --color-info: #11c9a6;
  --color-pink: #fe2a88;
  --color-purple: #9247f5;
  --color-lightsky: #cee7fe;
  --color-light: #5b5b5b;
  --color-dark: #262626;
  --color-white: #ffffff;
  --color-grey: #3b3b38;
  --color-black: #000000;
  --color-dark-grey: #343a40;
  --color-dark-hover: #23272b;
  --color-blue: #007bff;
  --color-success: #28a745;

  /* Shadows */
  --shadow-sm: 0 2px 5px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 4px 60px rgba(0, 0, 0, 0.1);
  --shadow-hover: 0px 4px 60px rgba(0, 0, 0, 0.07);

  /* Borders */
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 22px;
  --border-radius-full: 100px;

  /* Gradients */
  --gradient-pink-white: linear-gradient(94.9deg, #fe2a88 57.45%, #ffffff 94.26%);
  --gradient-white-pink: linear-gradient(92.9deg, #ffffff 3.5%, #fe2a88 30.43%);
  --gradient-blue-light: linear-gradient(135deg, #89f7fe 0%, #66a6ff 100%);
  --gradient-pink-red: linear-gradient(135deg, #f093fb 0%, #f5576c 100%);
}

.text-xs {
  font-size: var(--font-size-xs);
}
.text-s {
  font-size: var(--font-size-s);
}
.text-sm {
  font-size: var(--font-size-sm);
}
.text-md {
  font-size: var(--font-size-md);
}
.text-lg {
  font-size: var(--font-size-lg);
}
.text-xl {
  font-size: var(--font-size-xl);
}
.text-xxl {
  font-size: var(--font-size-xxl);
}

.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}

.info {
  color: var(--color-info);
}
.pink {
  color: var(--color-pink);
}
.bg-pink {
  background-color: var(--color-pink);
}
.purple {
  color: var(--color-purple);
}
.bg-purple {
  background-color: var(--color-purple);
}
.lightsky {
  color: var(--color-lightsky);
}
.light {
  color: var(--color-light);
}
.dark {
  color: var(--color-dark);
}
.bg-dark {
  background-color: var(--color-dark);
}
.ff-anton {
  font-family: var(--ff-anton);
}
.ff-manrope {
  font-family: var(--ff-manrope);
}

/*====== Navbar =====*/
.navbar {
  border-radius: var(--border-radius-md);
}
.navbar-expand-lg {
  padding: 4px 0px 4px 12px;
}
.navbar-collapse {
  justify-content: center;
}
.bg-grey {
  background-color: var(--color-grey);
}
.custom-container {
  max-width: 1280px;
}
.nav {
  transition: all 300ms linear;
}
.nav:hover {
  color: var(--color-pink);
}
.offcanvas-body {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.offcanvas-header {
  justify-content: space-between;
}
.nav-shadow {
  box-shadow: var(--shadow-lg);
}
.navbar-toggler {
  border: none;
  outline: none !important;
}
.addnew {
  background-color: white;
  border: none;
  position: relative;
  color: var(--color-pink);
}
.addnew::after {
  content: '';
  position: absolute;
  bottom: 6px;
  left: 6px;
  background-color: var(--color-pink);
  height: 1px;
  width: 71px;
}
.navbar-toggler-icon {
  background-image: url(/public/images/menu.png);
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.text-gradient-1 {
  background: var(--gradient-pink-white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-2 {
  background: var(--gradient-white-pink);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.heading-2 {
  font-size: var(--font-size-s);
}

.btn-2 {
  border-radius: var(--border-radius-full);
  border: 1px solid var(--color-white);
  padding: 10px 17px;
  color: var(--color-white);
  font-size: var(--font-size-xs);
  font-family: var(--ff-manrope);
  box-shadow: var(--shadow-hover);
  background: rgba(var(--color-white), 0.2);
  transition: all 300ms linear;
  cursor: auto !important;
}

.btn-2:hover {
  box-shadow: var(--shadow-hover);
  background: rgba(var(--color-white), 0.2);
  border: 1px solid var(--color-white);
}

.commonbtn {
  background-color: var(--color-pink);
  padding: 14px 40px;
  border-radius: var(--border-radius-md);
  border: 1px solid transparent;
  font-weight: 600;
  font-family: var(--ff-manrope);
  font-size: var(--font-size-xs);
  transition: all 0.5s;
  color: var(--color-white);
}

.commonbtn:hover {
  background-color: var(--color-white);
  color: var(--color-pink);
  border: 1px solid var(--color-pink);
}

.featurebtn {
  box-shadow: var(--shadow-hover);
  padding: 14px 22px;
  border-radius: var(--border-radius-full);
  border: none;
  font-size: var(--font-size-xs);
  background-color: var(--color-white);
  width: 140px;
}

.featurebtn2 {
  padding: 14px 40px;
  border-radius: var(--border-radius-md);
  color: var(--color-pink);
  font-weight: 600;
  font-family: var(--ff-manrope);
  font-size: var(--font-size-xs);
  border: 1px solid var(--color-pink);
  background-color: var(--color-white);
  transition: all 0.5s;
}

.featurebtn2:hover {
  background-color: var(--color-pink);
  color: var(--color-white);
  border: 1px solid transparent;
}

.feature-box {
  height: 225px;
  background-color: var(--color-white);
  padding: 20px;
  border-radius: var(--border-radius-lg);
  position: absolute;
  bottom: 2%;
  margin: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.feature-image {
  width: 630px;
  height: 591px;
  padding: 52px 40px;
  border-radius: var(--border-radius-lg);
}

.feature-img {
  border-radius: var(--border-radius-lg);
}

.joinnow {
  border: 1px solid var(--color-dark);
  padding: 14px 40px;
  border-radius: var(--border-radius-md);
  background-color: var(--color-white);
  transition: all 200ms linear;
}

.joinnow:hover {
  background-color: var(--color-dark);
  color: var(--color-white);
}

.footer-icons {
  transition: all 200ms linear;
  width: 50.48px;
  height: 50.48px;
  background: rgba(var(--color-white), 0.1);
  border: 2.1px solid rgba(var(--color-black), 0.1);
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-icons:hover {
  box-shadow: 0 0 16px rgba(var(--color-black), 0.1);
}

.subscribe_btn {
  padding: 0 30px;
  border-radius: var(--border-radius-md);
  border: 1px solid #008afc;
  color: #008afc;
}

.subscribecont_side_p {
  padding: 12px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .featureimg {
    width: 100%;
    height: 380px;
  }
  .feature-img {
    height: 500px !important;
    margin-top: 20px;
  }
}

@media (max-width: 992px) {
  .featureimg {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .feature-img {
    margin: 0 auto;
  }
  .corpsimg {
    width: 323px;
    height: 396px;
  }
}

@media (max-width: 576px) {
  :root {
    /* Font Sizes */
    --font-size-xs: 12px;
    --font-size-s: 16px;
    --font-size-sm: 16px;
    --font-size-md: 16px;
    --font-size-lg: 18px;
    --font-size-xl: 28px;
    --font-size-xxl: 37px;
  }
  .feature-img {
    width: 100% !important;
  }
  .featureimg {
    width: 100%;
    height: 265px;
  }
  .feature-image {
    width: 343px;
    height: 321px;
    padding: 28px 21px;
  }

  .logo {
    width: 42px;
    height: 36px;
  }
}

@media (min-width: 578px) {
  .lh-40 {
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .commonbtn {
    padding: 17px 50px;
  }
  .featurebtn2 {
    padding: 17px 55px;
  }
  .heading-2 {
    font-size: var(--font-size-lg);
  }
  .btn-2 {
    padding: 14px 23px;
  }
  .navbar {
    border-radius: var(--border-radius-lg);
  }
  .joinnow {
    padding: 17px 56px;
  }
  .subscribecont_side_p {
    padding: 18px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    justify-content: space-between;
    display: flex;
    padding: 6px 30px;
  }
  .h-700 {
    height: 700px;
  }
  .feature-image {
    max-width: 50%;
    height: 100%;
  }
  .corpsimg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.z-50 {
  z-index: 50;
}
.z-100 {
  z-index: 100;
}

