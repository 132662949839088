.photo-container {
  width: 400px;
  min-width: fit-content;
}

.scaled-img {
  width: 100%;
  object-fit: contain;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

