/* src/app.scss */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.photo-upload {
  .dropzone {
    border: 2px dashed #cccccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
  }

  aside {
    margin-top: 20px;

    .card {
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 10px;
      overflow: hidden;
      width: 100px; /* Fixed width */

      .card-img-top {
        width: 100%; /* Ensures the image fits the card width */
        height: auto; /* Maintains aspect ratio */
      }

      .card-body {
        padding: 10px;

        .card-text {
          font-size: 12px;
          word-wrap: break-word;
        }

        button {
          margin-top: 5px;
          padding: 5px 10px;
          font-size: 12px;
        }
      }
    }
  }
}

.photo-container {
  width: 400px;
  min-width: fit-content;

  .scaled-img {
    width: 100%;
    object-fit: contain;
  }
}

.mapkit-circle-annotation {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  background-color: #ccc;
  text-align: center;
  line-height: 32px;
}

/* MainChart.css */
.main-chart-container {
  width: 100%;
  height: 100%;
  background-color: var(--color-white) !important;
  border-radius: var(--border-radius-lg);
  padding: 20px 12px;
  box-shadow: var(--shadow-hover);
}

a:not(:disabled) {
  cursor: pointer;
}

/* Apply hand cursor to all buttons that are not disabled */
button:not(:disabled) {
  cursor: pointer;
}

/* Apply hand cursor to any other clickable elements like inputs of type submit or button */
input[type="button"]:not(:disabled),
input[type="submit"]:not(:disabled) {
  cursor: pointer;
}


.cursor-pointer {
  cursor: pointer;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: a light overlay */
  z-index: 9999; /* Ensure it stays above other content */
}

.loading-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.image-container {
  position: relative;
  overflow: hidden;
  height: 300px; /* Adjust this height to your preference */
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto; /* Maintain aspect ratio */
  object-fit: cover;
}

.custom-img {
  max-height: 70vh;
  width: 100%;
  object-fit: contain;
}