.avatar-group {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: -10px;
  background-color: white;
  object-fit: cover;
  z-index: 1;
}

.avatar:first-child {
  margin-left: 0;
}

.avatar.extra {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  color: white;
  font-size: 14px;
  font-weight: bold;
  z-index: 0;
}
