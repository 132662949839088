@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

:root {
  /* Font Sizes */
  --font-size-extrasmall: 16px;
  --font-size-small: 18px;
  --font-size-large: 60px;

  /* Font Family */
  --ff-plus: "Plus Jakarta Sans", sans-serif;
  --ff-inter: "Inter", sans-serif;

  /* Colors */
  --color-pink: #fe2a88;
  --color-mediumblack: #4d4d51;
  --color-lightgrey: #52525b;
  --color-lightblack: #262626;
  --color-black: #000000;
  --color-link-hover: #007bff; /* Link hover color */
  --color-lightblue: #6c63ff;
  --color-darkblue: #3f3d56;
  --color-green: #28a745;
  --color-dark: #343a40;
  --color-darker: #23272b;
  --color-darkest: #1d2124;
  --color-lightblack-background: #edeaff;
  --color-gradient-blue: #66a6ff;
  --color-gradient-cyan: #89f7fe;
  --color-gradient-violet: #7f61f9;
  --color-gradient-purple: #6fd3fe;
  --color-gradient-pink: #ff4d6d;
  --color-gradient-pink-hover: #e63950;
  --color-white: #ffffff;
  --color-light-gradient-start: #dfd5ff;
  --color-light-gradient-end: #b8f5ff;
}

.font-xs {
  font-size: var(--font-size-extrasmall);
}

.font-s {
  font-size: var(--font-size-small);
}

.font-xxl {
  font-size: var(--font-size-large);
}

.pink {
  color: var(--color-pink);
}

.common-head {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--ff-plus);
}

.color-light {
  color: var(--color-lightgrey);
}

.color-dark {
  color: var(--color-lightblack);
}

.color-mediumblack {
  color: var(--color-mediumblack);
}

.bg-dark {
  background-color: var(--color-dark);
}

.ff-plus {
  font-family: var(--ff-plus);
}

.ff-inter {
  font-family: var(--ff-inter);
}

@media (max-width: 768px) {
  :root {
    /* Font Sizes */
    --font-size-xs: 15px;
    --font-size-xxl: 57px;
  }
}


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(101, 100, 106, 0.7);
  }
  70% {
    box-shadow: 0 0 20px 20px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.pulsing-aura {
  animation: pulse 2s infinite;
}

.max-w-260 {
  max-width: 260px;
}

.profile-container {
  max-width: 600px;
}

.planbtn {
  position: relative;
  overflow: hidden;
  width: 100%;
  border: none; /* Remove the default border */
  border-radius: 5px;
  padding: 15px 0px;
  color: var(--color-lightblack);
  font-family: var(--ff-plus);
  font-size: var(--font-size-extrasmall);
  font-weight: 500;
  background-color: var(--color-white);
  z-index: 1;
  transition: all 200ms linear;
}

.planbtn:hover {
  background-color: var(--color-lightblack);
  color: var(--color-white);
}

.planbtn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 1.5px; /* Adjust this value to control the border thickness */
  background: linear-gradient(98.24deg, var(--color-gradient-cyan) 0%, var(--color-gradient-violet) 100%);
  -webkit-mask: linear-gradient(var(--color-white) 0 0) content-box, linear-gradient(var(--color-white) 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.profile-card {
  box-shadow: 0 4px 8px rgba(var(--color-black), 0.1);
  border: none;
}

.profile-picture {
  width: 120px;
  height: 120px;
  border: 16px solid var(--color-lightblack-background);
}

.profile-info p {
  margin: 10px 0;
}

.profile-container {
  max-width: 1150px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;
}

.box-shadow {
  box-shadow: 0px 4px 60px 0px rgba(var(--color-black), 0.12);
}

.profile-box {
  height: 400px;
  border-radius: 22px;
  box-shadow: 0px 4px 60px 0px rgba(var(--color-black), 0.12);
}

.profile-box-main {
  border-radius: 22px;
  box-shadow: 0px 4px 60px 0px rgba(var(--color-black), 0.12);
  background-color: var(--color-white);
}

.bg-gradient-profile {
  background: linear-gradient(91.53deg, var(--color-light-gradient-start) 0.93%, var(--color-light-gradient-end) 100%);
  height: 221px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}

.child-padding {
  margin-top: 110px;
}

.min-781 {
  max-width: 781px;
}

/* Plan */

.profile-plan-card {
  position: relative;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(var(--color-black), 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.profile-plan-card::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: linear-gradient(135deg, var(--color-gradient-cyan) 0%, var(--color-gradient-blue) 100%);
  border-radius: inherit;
  padding: 4px;
}

.profile-plan-card .card-body {
  position: relative;
  z-index: 1;
  border-radius: 6px !important;
  padding: 32px 20px;
  text-align: start;
  background-color: var(--color-white);
  overflow: hidden;
  transition: 0.3s;
}

.profile-plan-card .card-body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px; /* Same border-radius as the card */
  padding: 1.2px; /* Adjust border thickness here */
  background: linear-gradient(86.73deg, var(--color-gradient-purple) 3.13%, var(--color-gradient-violet) 95.46%);
  -webkit-mask: linear-gradient(var(--color-white) 0 0) content-box, linear-gradient(var(--color-white) 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
  transition: opacity 0.3s; /* Smooth transition for opacity change */
}

.profile-plan-card .card-body:hover {
  background: linear-gradient(91.53deg, var(--color-light-gradient-start) 0.93%, var(--color-light-gradient-end) 100%);
}

.profile-plan-card .card-body:hover::before {
  opacity: 0; /* Hide the border on hover */
}

.profile-plan-highlight::before {
  background: var(--gradient-pink-red);
}

.card-title {
  font-family: var(--ff-plus);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.card-price {
  font-size: 2rem;
  font-weight: bold;
  margin: 15px 0;
}



.card-text {
  margin-bottom: 20px;
  color: var(--color-lightgrey);
}

.custom_mw_subscribe_h {
  max-width: 379px;
}

.hover-btn:hover {
  opacity: 0.7;
  transition: 300ms;
}

.list-unstyled {
  padding: 0;
  margin: 0 0 20px;
  list-style-type: none;
}

.list-unstyled li {
  margin-bottom: 10px;
  font-size: 1rem;
  color: var(--color-black);
}

.bi-check-circle {
  color: var(--color-green);
  margin-right: 10px;
}

.bi-info-circle {
  color: var(--color-link-hover);
  margin-left: 5px;
}

.btn-outline-primary {
  border-color: var(--color-link-hover);
  color: var(--color-link-hover);
}

.btn-outline-primary:hover {
  background-color: var(--color-link-hover);
  color: var(--color-white);
}

.btn-dark {
  color: var(--color-white);
  background-color: var(--color-dark);
  border-color: var(--color-dark);
}

.btn-dark:hover {
  background-color: var(--color-darker);
  border-color: var(--color-darkest);
}

/* 
.profile-plan-card:hover {
  transform: translateY(-5px);
} */

.my-images-section { 
  border-radius: 20px;
  background-color: var(--color-white);
  box-shadow: 0 2px 15px rgba(var(--color-black), 0.1);
}

.image-wrapper {
  overflow: hidden;
  border-radius: 15px;  
}

.image-wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

.my-swiper .swiper-button-next,
.my-swiper .swiper-button-prev {
  color: var(--color-lightblue);
  transition: color 0.3s;
}

.my-swiper .swiper-button-next:hover,
.my-swiper .swiper-button-prev:hover {
  color: var(--color-darkblue);
}

.my-swiper .swiper-pagination-bullet {
  background-color: var(--color-lightblue);
}

.my-swiper .swiper-pagination-bullet-active {
  background-color: var(--color-darkblue);
}

/* photo gallery */

.my-photo-gallery-section {
  background-color: var(--color-white); 
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(var(--color-black), 0.1);
}

.image-wrapper {
  overflow: hidden;
  border-radius: 8px;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  transition: transform 0.3s;
}

.image-wrapper img:hover {
  transform: scale(1.05);
}

.btn-pink {
  background-color: var(--color-gradient-pink);
  color: var(--color-white);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.btn-pink:hover {
  background-color: var(--color-gradient-pink-hover);
  color: var(--color-white);
}

@media (min-width: 992px) {
  .profile-plan-card .card-body {
    padding: 31px 33px 39px;
  }
}

.z-150 {
  z-index: 1050;
}
