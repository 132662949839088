:root {
  /* Font Sizes */
  --font-size-2lg: 28px;
  /* Colors */
  --color-azureish-white: #d7e1f9;
  --color-antique-white: #faecdc;
  --color-pink: #fe2a88;
  --color-aero-blue: #cff4e6;
  --color-orange: #ff8b1f;
  --color-blue: #0057ff;
  --color-light-green: #008d57;
  --color-cultured: #f5f5f5;
  --color-azure: #008afc;
  --color-gainsboro: #dddddd;
  --color-gray: #7c7c7c;
  --color-shamrock-green: #029f64;
  --color-red: #ff0000;
}
.bg-azureish-white {
  background-color: var(--color-azureish-white);
}
.gray {
  color: var(--color-gray);
}
.shamrock-green {
  color: var(--color-shamrock-green);
}
.bg-antique-white {
  background-color: var(--color-antique-white);
}
.bg-cultured {
  background-color: var(--color-cultured);
}
.bg-aero-blue {
  background-color: var(--color-aero-blue);
}
.blue {
  color: var(--color-blue);
}
.light-green {
  color: var(--color-light-green);
}
.orange {
  color: var(--color-orange);
}
.text-2lg {
  font-size: var(--font-size-2lg);
}
.lh-38 {
  line-height: 38px;
}
.lh-24 {
  line-height: 24px;
}

.colorfull-card {
  padding: 16px 14px;
  border-radius: 22px;
  max-width: 369px;
}
.icon-box {
  height: 48px;
  width: 48px;
  border-radius: 12px;
}
.chart-image {
  box-shadow: 0px 4px 60px 0px #00000012;
  border-radius: 22px;
}
.last-time-box {
  padding: 20px;
  box-shadow: 0px 4px 60px 0px #00000012;
  border-radius: 22px;
  max-height: 200px;
}
.timer-svg {
  background-color: #9247f51a;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.engagement-box {
  padding: 16px 20px;
  box-shadow: 0px 4px 60px 0px #00000012;
  border-radius: 22px;
  max-height: 200px;
}
.social-icon-box {
  min-height: 56px;
  border-radius: 12px;
}
.btn-padding {
  padding: 17px 31px;
}
.gap-tabs {
  gap: 100px;
}
.active {
  color: var(--color-azure);
  position: relative;
}
.tabs {
  position: relative;
}
.tabs::after {
  position: absolute;
  width: 100%;
  background-color: var(--color-gainsboro);
  bottom: -17px;
  height: 1px;
  left: 0;
  content: "";
  border-radius: 10px;
  z-index: 1;
}
.active::after {
  position: absolute;
  width: 2.5px !important;
  background-color: var(--color-azure);
  top: 15px !important;
  height: 32px !important;
  right: -15% !important;
  content: "";
  border-radius: 10px;
  z-index: 2;
}

.tab-content {
  margin-top: 17px;
}
.convention-tab-box {
  border: 1px solid var(--color-gainsboro);
  border-radius: 16px;
  max-width: 369px;
}
.carnival-img {
  width: 49px;
  height: 49px;
}
.pt-14 {
  padding-top: 14px;
}
.profiles-img {
  max-width: 102px;
  max-height: 28px;
}
.photoshoot-box {
  padding: 17px 16px;
  border: 1px solid var(--color-gainsboro);
  border-radius: 12px;
}
.moutain-men-image {
  max-width: 337px;
  max-height: 210px;
  border-radius: 12px;
}
.btn-complete {
  padding: 6px 16px;
  border-radius: 100px;
}
.btn-complete-color {
  background-color: #029f641a;
  color: var(--color-shamrock-green);
}
.btn-incomplete-color {
  background-color: #ff00001a;
  color: var(--color-red);
}
.max-w-369 {
  max-width: 369px;
}
.btn-delete {
  margin-top: 18px;
  border: 1px solid var(--color-pink);
  border-radius: 12px;
  transition: 300ms;
  padding: 14.5px;
}
.btn-delete:hover {
  margin-top: 18px;
  border: 1px solid var(--color-pink);
  border-radius: 12px;
  background-color: var(--color-pink) !important;
  color: white;
  padding: 14.5px;
}
.swiper-container {
  position: relative;
}
.swiper-navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 100%;
}
.swiper-button-prev,
.swiper-button-next {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  margin: 0 5px;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "" !important;
}
.arrow-btn {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #e5f3ff;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .convention-tab-box {
    max-width: 400px;
  }
}
@media (max-width: 576px) {
  .colorfull-card {
    max-width: 316px;
  }
  .gap-tabs {
    gap: 70px;
  }
  .active::after {
    content: unset;
  }
}

/* BookingRequest.css */
.booking-request-container {
  padding: 20px;
}

.booking-request-table {
  margin-top: 20px;
}

.booking-request-table th {
  background-color: #343a40;
  color: white;
}

.booking-request-table tbody tr {
  vertical-align: middle;
}

.color-bar {
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 123%;
  margin-right: 10px;
  top: 0;
  left: 0;
}

@media (max-width: 600px) {
  .color-bar {
    position: absolute;
    display: inline-block;
    width: 8px;
    height: 100%;
    margin-right: 10px;
    top: 0;
    left: 0;
  }

  .w-lg-100 {
    width: 100%;
  }
}

.table > :not(caption) > * > * {
  border-bottom: 0 !important;
}

.color-bar.purplee {
  background-color: purple !important;
}
.color-bar.pink {
  background-color: pink;
}
.color-bar.green {
  background-color: green;
}
.color-bar.blue {
  background-color: blue;
}
.color-bar.orange {
  background-color: orange;
}
.color-bar.cyan {
  background-color: cyan;
}

.action-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.booking-request-table tbody tr td {
  background-color: #f9f9f9;
  border: 0 !important;
  padding: 15px;
  position: relative;
  white-space: nowrap;
}
.booking-request-table {
  border-spacing: 30px !important;
}
.booking-request-table thead tr th {
  border: 0 !important;
  padding: 25px;
  position: relative;
}
.booking-request-table tbody tr {
  background-color: #f9f9f9;
  border: 0 !important;
  vertical-align: middle;
  border-bottom: 20px solid white !important;
}
.booking-request-table thead tr {
  background-color: #f9f9f9;
  border: 0 !important;
  vertical-align: middle;
  /* border-bottom: 20px solid white !important; */
  margin-bottom: 10px;
}

.border-22 {
  border-top-right-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
}
.border-l-22 {
  border-top-left-radius: 22px !important;
  border-bottom-left-radius: 22px !important;
}

/* dashboard.css */

/* Remove chevron and apply custom styles */
.dropdown-toggle::after {
  display: none !important; /* Hide the default chevron */
}

.dropdown-menu {
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  margin-top: 5px;
  min-width: 120px;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 1000;
}

.dropdown-item {
  color: #333;
  padding: 8px 16px;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f7f7f7;
}

.dropdown-item {
  color: black; /* Default color for dropdown items */
  font-family: var(--ff-manrope);
  font-size: var(--font-size-sm);
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

/* Change color to black on active or focused state */
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  color: black !important; /* Override Bootstrap styles */
  background-color: var(
    --color-azureish-white
  ); /* Optional: Change background color */
}

.colorfull-card {
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
}

.icon-box {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.ff-manrope {
  font-family: "Manrope", sans-serif;
}

/* Add additional styles as needed */

/* Add this to your CSS file (e.g., dashboard.css) */

.tab-content {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.tab-content.active-tab {
  opacity: 1;
  transform: translateY(0);
}

.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
