.choose-role {
  padding: 20px;
}

.roles-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.role-card {
  position: relative;
  margin: 10px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.role-card:hover {
  transform: scale(1.05);
}

.role-card.selected {
  border: 2px solid #007bff;
}

.role-image-container {
  width: 400px;
  max-height: 900px;
}

.role-image {
  width: 100%;
  object-fit: contain;
}

.role-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.role-card.selected .role-overlay,
.role-card:hover .role-overlay {
  opacity: 1;
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.benefits-list li {
  margin-bottom: 5px;
}
