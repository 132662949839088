.weather-forecast-container {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    gap: 10px;
  }
  
  .weather-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #C0E3FF;
    padding: 10px;
    border-radius: 8px;
    min-width: 120px; /* Adjust based on your design */
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .weather-date {
    font-size: 14px;
    font-weight: bold;
  }
  
  .weather-icon {
    width: 50px;
    height: 50px;
    margin: 10px 0;
  }
  
  .weather-temp {
    font-size: 24px;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .weather-condition {
    font-size: 12px;
    color: #555;
  }